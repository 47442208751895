<template>
  <div class="global-filters">
    <SelectKnowledgeParameters
      v-if="isParametric"
      @update-product-filters="parametricFilters = $event"
    />
    <tags-wrapper
      class="mt-2"
      v-if="knowledges.length && !isParametric"
      :label="$t('ask.knowledge-base')"
      :tags="tagKnowledges"
      :selectedTags="chosenKnowledges"
      :defaultShowMore="true"
    >
      <template v-slot:tag="{ tag }">
        <Tag
          :tag="tag"
          :selected="isSelected(tag.id)"
          @focus="handleKnowledge"
        />
      </template>
    </tags-wrapper>
    <tags-wrapper
      v-if="labels.length"
      class="mt-2"
      :label="$t('knowledge.search-admin.labels')"
      :tags="labels"
      :selectedTags="selectedTags"
    >
      <template v-slot:tag="{ tag }">
        <Tag :tag="tag" :selected="isSelected(tag.id)" @focus="handleLabel" />
      </template>
    </tags-wrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SelectKnowledgeParameters from '@/components/Commons/KnowledgeParameters/SelectKnowledgeParameters';
import TagsWrapper from '@/components/SuperSearch/TagsWrapper';
import Tag from '@/components/SuperSearch/Tag';

export default {
  name: 'AskFilters',
  components: {
    SelectKnowledgeParameters,
    TagsWrapper,
    Tag,
  },
  data: () => ({
    selectedTags: [],
    tagKnowledges: [],
    chosenKnowledges: [],
    labels: [],
    chosenLabels: [],
    parametricFilters: [],
  }),
  async mounted() {
    if (!this.isParametric) {
      if (this.knowledges.length === 0) await this.getKnowledges();
      this.tagKnowledges = this.knowledges.map((d) => ({
        id: d.id,
        label: d.label,
      }));
      this.chosenKnowledges = [...this.tagKnowledges];
    }
    this.selectedTags = this.newSelectedTags();
    await this.loadLabels();
    this.updateSelectedOptions();
  },
  methods: {
    async loadLabels() {
      let labels =
        await this.$services.contentParameterLabels.getContentParameterLabels();
      this.labels = labels.map((label) => {
        label.key = 'categories';
        label.label = label.labelName;
        label.value = label.id;
        return label;
      });
      this.labels.unshift({
        id: 'none',
        key: 'categories',
        label: this.$t('filters.labels-filter.no-label'),
        value: null,
        icon: 'tag',
      });
    },
    newSelectedTags() {
      return [...this.chosenLabels, ...this.chosenKnowledges];
    },
    async handleLabel(label) {
      const index = this.chosenLabels.findIndex((l) => l.value === label.value);
      index === -1
        ? this.chosenLabels.push(label)
        : this.chosenLabels.splice(index, 1);
      this.selectedTags = this.newSelectedTags();
      this.updateSelectedOptions();
    },
    async handleKnowledge(knowledge) {
      const index = this.chosenKnowledges.findIndex(
        (l) => l.id === knowledge.id,
      );
      if (index === -1) {
        this.chosenKnowledges.push(knowledge);
      } else {
        if (this.chosenKnowledges.length <= 1) {
          this.$message({
            message: this.$t('ask.at-least-one-kb'),
            type: 'error',
          });
          return;
        } else {
          this.chosenKnowledges.splice(index, 1);
        }
      }
      this.selectedTags = this.newSelectedTags();
      this.updateSelectedOptions();
    },
    isSelected(tagId) {
      return this.selectedTags.map((t) => t.id).includes(tagId);
    },
    updateSelectedOptions() {
      this.$emit('update-selected-options', {
        ...this.parametricFiltersAsOptions,
        ...this.knowledgeFiltersAsOptions,
        ...this.labelFiltersAsOptions,
      });
    },
    ...mapActions('knowledgeModule', ['getKnowledges']),
  },
  watch: {
    parametricFilters() {
      this.parametricFiltersAsOptions =
        this.parametricFilters.length && this.isParametric
          ? this.parametricFilters.reduce((acc, filter) => {
              acc[filter.key] = filter.values;
              return acc;
            }, {})
          : {};
      this.updateSelectedOptions();
    },
  },
  computed: {
    knowledgeFiltersAsOptions() {
      return this.chosenKnowledges.length && !this.isParametric
        ? { knowledgeId: this.chosenKnowledges.map((label) => label.id) }
        : {};
    },
    labelFiltersAsOptions() {
      return this.chosenLabels.length
        ? {
            categories: this.chosenLabels.map((label) =>
              label.id === 'none' ? null : label.id,
            ),
          }
        : {};
    },
    ...mapGetters(['isParametric', 'hasCompanyPreferenceWithValue']),
    ...mapGetters('knowledgeModule', ['knowledges', 'editingLanguage']),
  },
};
</script>

<style scoped lang="scss">
.global-filters {
  padding: 12px;
}
</style>
